import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div className="steps2-container thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container1 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="thq-heading-2">
              Discover the Power of Our Products
            </h2>
            <p className="thq-body-large">
              Are you feeling lost or confused about your life path? Do you need
              clarity and guidance on important decisions? Psychic readings can
              provide insights and perspectives that may help you navigate
              through challenges and uncertainties. Whether you seek answers
              about your relationships, career, or personal growth, a psychic
              reading can offer valuable guidance and support.
            </p>
            <div className="steps2-actions"></div>
          </div>
          <div className="steps2-container2">
            <div className="steps2-container3 thq-card">
              <h2>
                {props.step1Title ?? (
                  <Fragment>
                    <h2 className="steps2-text16 thq-heading-2">
                      Find Your Psychic
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step1Description ?? (
                  <Fragment>
                    <span className="steps2-text20 thq-body-small">
                      Browse through our list of experienced psychics and choose
                      the one that resonates with you.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text04 thq-heading-3">01</label>
            </div>
            <div className="steps2-container4 thq-card">
              <h2>
                {props.step2Title ?? (
                  <Fragment>
                    <h2 className="steps2-text21 thq-heading-2">
                      Select Your Reading
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step2Description ?? (
                  <Fragment>
                    <span className="steps2-text19 thq-body-small">
                      Choose from a variety of readings such as Tarot,
                      Clairvoyance, Coffee Cup, or Dream Interpretation.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text07 thq-heading-3">02</label>
            </div>
            <div className="steps2-container5 thq-card">
              <h2>
                {props.step3Title ?? (
                  <Fragment>
                    <h2 className="steps2-text18 thq-heading-2">
                      Connect with Your Psychic
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step3Description ?? (
                  <Fragment>
                    <span className="steps2-text17 thq-body-small">
                      Connect with your chosen psychic for a personalized and
                      insightful reading session.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text10 thq-heading-3">03</label>
            </div>
            <div className="steps2-container6 thq-card">
              <h2>
                {props.step4Title ?? (
                  <Fragment>
                    <h2 className="steps2-text15 thq-heading-2">
                      Gain Clarity and Guidance
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.step4Description ?? (
                  <Fragment>
                    <span className="steps2-text14 thq-body-small">
                      Receive guidance, clarity, and answers to your questions
                      through the psychic reading.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text13 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  step4Description: undefined,
  step4Title: undefined,
  step1Title: undefined,
  step3Description: undefined,
  step3Title: undefined,
  step2Description: undefined,
  step1Description: undefined,
  step2Title: undefined,
}

Steps2.propTypes = {
  step4Description: PropTypes.element,
  step4Title: PropTypes.element,
  step1Title: PropTypes.element,
  step3Description: PropTypes.element,
  step3Title: PropTypes.element,
  step2Description: PropTypes.element,
  step1Description: PropTypes.element,
  step2Title: PropTypes.element,
}

export default Steps2
