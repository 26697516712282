import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero17.css'

const Hero17 = (props) => {
  return (
    <div className="hero17-header78">
      <div className="hero17-column thq-section-padding thq-section-max-width">
        <div className="hero17-content">
          <h1>
            {props.heading1 ?? (
              <Fragment>
                <h1 className="hero17-text5 thq-heading-1">
                  Find Your Perfect Psychic Match
                </h1>
              </Fragment>
            )}
          </h1>
          <p>
            {props.content1 ?? (
              <Fragment>
                <p className="hero17-text3 thq-body-large">
                  Connect with experienced psychics offering Tarot,
                  Clairvoyance, Coffee Cup, and Dream Interpretation services.
                  Discover insights and guidance for your life&apos;s journey.
                </p>
              </Fragment>
            )}
          </p>
        </div>
        <div className="hero17-actions">
          <a
            href="https://apps.apple.com/app/apple-store/id1454411082?pt=120518910&amp;ct=psychichouse-website&amp;mt=8"
            target="_blank"
            rel="noreferrer noopener"
            className="hero17-button thq-button-filled"
          >
            <span>
              {props.action1 ?? (
                <Fragment>
                  <span className="hero17-text4 thq-body-small">
                    Download for iOS
                  </span>
                </Fragment>
              )}
            </span>
          </a>
        </div>
      </div>
      <div className="hero17-content1">
        <div className="hero17-row-container thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              src="/0x0ss-1500w.png"
              className="hero17-placeholder-image thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero17-placeholder-image01 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero17-placeholder-image02 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero17-placeholder-image03 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero17-placeholder-image04 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image6Alt}
              src={props.image6Src}
              className="hero17-placeholder-image05 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero17-placeholder-image06 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero17-placeholder-image07 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero17-placeholder-image08 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero17-placeholder-image09 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero17-placeholder-image10 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="/0x0ss-6-1500w.png"
              className="hero17-placeholder-image11 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="hero17-container1">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero17.defaultProps = {
  image2Src: '/0x0ss-2-1500w.png',
  image6Alt: 'User review 2',
  content1: undefined,
  image8Src:
    'https://images.unsplash.com/photo-1522725843938-035891590561?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzExMjI5MHw&ixlib=rb-4.0.3&q=80&w=1500',
  action1: undefined,
  image5Alt: 'User review 1',
  image5Src: '/0x0ss-5-1500w.png',
  heading1: undefined,
  image3Src: '/0x0ss-3-1500w.png',
  image1Src: '/0x0ss-1500w.png',
  image1Alt: 'Screenshot of Tarot reading in progress',
  image4Alt: 'Screenshot of Dream Interpretation tool',
  image3Alt: 'Screenshot of Coffee Cup reading feature',
  image4Src: '/0x0ss-4-1500w.png',
  image6Src: '/0x0ss-6-1500w.png',
  image2Alt: 'Screenshot of Clairvoyance session',
}

Hero17.propTypes = {
  image2Src: PropTypes.string,
  image6Alt: PropTypes.string,
  content1: PropTypes.element,
  image8Src: PropTypes.string,
  action1: PropTypes.element,
  image5Alt: PropTypes.string,
  image5Src: PropTypes.string,
  heading1: PropTypes.element,
  image3Src: PropTypes.string,
  image1Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image4Src: PropTypes.string,
  image6Src: PropTypes.string,
  image2Alt: PropTypes.string,
}

export default Hero17
