import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial17.css'

const Testimonial17 = (props) => {
  return (
    <div className="testimonial17-testimonial13 thq-section-padding">
      <div className="testimonial17-max-width thq-section-max-width">
        <div className="testimonial17-container">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="testimonial17-text22 thq-heading-2">
                  User Reviews
                </h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="testimonial17-text23 thq-body-small">
                  Read what our users have to say about their experiences with
                  our psychics.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="testimonial17-container01 thq-grid-2">
          <div className="testimonial17-accent2-bg thq-animated-card-bg-2">
            <div className="testimonial17-accent1-bg thq-animated-card-bg-1">
              <div data-animated="true" className="testimonial17-card thq-card">
                <div className="testimonial17-container02">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial17-image"
                  />
                  <div className="testimonial17-container03">
                    <strong>
                      {props.author1Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text21 thq-body-large">
                            Sara Johnson
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author1Position ?? (
                        <Fragment>
                          <span className="testimonial17-text26 thq-body-small">
                            Pisces, 23
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review1 ?? (
                    <Fragment>
                      <span className="testimonial17-text15 thq-body-small">
                        I was amazed by the accuracy of the Tarot reading I
                        received. The psychic was insightful and provided
                        guidance that truly resonated with me.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="testimonial17-accent2-bg1 thq-animated-card-bg-2">
            <div className="testimonial17-accent1-bg1 thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container04">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial17-image1"
                  />
                  <div className="testimonial17-container05">
                    <strong>
                      {props.author2Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text20 thq-body-large">
                            Michael Lee
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author2Position ?? (
                        <Fragment>
                          <span className="testimonial17-text16 thq-body-small">
                            Aries 31
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review2 ?? (
                    <Fragment>
                      <span className="testimonial17-text14 thq-body-small">
                        The Clairvoyance session I had was eye-opening. The
                        psychic was able to tap into aspects of my life that I
                        hadn&apos;t shared with anyone. Highly recommended!
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="testimonial17-accent2-bg2 thq-animated-card-bg-2">
            <div className="testimonial17-accent1-bg2 thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card2"
              >
                <div className="testimonial17-container06">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial17-image2"
                  />
                  <div className="testimonial17-container07">
                    <strong>
                      {props.author3Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text25 thq-body-large">
                            Sophie Martinez
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author3Position ?? (
                        <Fragment>
                          <span className="testimonial17-text17 thq-body-small">
                            Taurus, 30
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review3 ?? (
                    <Fragment>
                      <span className="testimonial17-text27 thq-body-small">
                        Dream Interpretation helped me understand recurring
                        dreams that had been bothering me for months. The
                        psychic&apos;s interpretation brought clarity and peace
                        of mind.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="testimonial17-accent1-bg3 thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card3"
              >
                <div className="testimonial17-container08">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="testimonial17-image3"
                  />
                  <div className="testimonial17-container09">
                    <strong>
                      {props.author4Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text19 thq-body-large">
                            David Brown
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author4Position ?? (
                        <Fragment>
                          <span className="testimonial17-text18 thq-body-small">
                            Gemini, 45
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review4 ?? (
                    <Fragment>
                      <span className="testimonial17-text24 thq-body-small">
                        The Coffee Cup reading I received was surprisingly
                        accurate. It provided me with valuable insights and a
                        new perspective on my current situation.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial17.defaultProps = {
  review2: undefined,
  review1: undefined,
  author1Src:
    'https://images.unsplash.com/photo-1584891800774-6f8c93265d8a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzA0NzY4MXw&ixlib=rb-4.0.3&q=80&w=200',
  author2Position: undefined,
  author3Position: undefined,
  author4Position: undefined,
  author3Src:
    'https://images.unsplash.com/photo-1520626337972-ebf863448db6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzA0NzY4Mnw&ixlib=rb-4.0.3&q=80&w=200',
  author4Name: undefined,
  author2Name: undefined,
  author1Name: undefined,
  heading1: undefined,
  author3Alt: 'Sophie Martinez Image',
  author4Alt: 'David Brown Image',
  content1: undefined,
  review4: undefined,
  author3Name: undefined,
  author1Position: undefined,
  author2Alt: 'Michael Lee Image',
  author2Src:
    'https://images.unsplash.com/photo-1616337900440-056dd0f082d3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzA0NzY4MXw&ixlib=rb-4.0.3&q=80&w=200',
  author1Alt: 'Sara Johnson Image',
  review3: undefined,
  author4Src:
    'https://images.unsplash.com/photo-1527525443983-6e60c75fff46?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzA0NzY4MXw&ixlib=rb-4.0.3&q=80&w=200',
}

Testimonial17.propTypes = {
  review2: PropTypes.element,
  review1: PropTypes.element,
  author1Src: PropTypes.string,
  author2Position: PropTypes.element,
  author3Position: PropTypes.element,
  author4Position: PropTypes.element,
  author3Src: PropTypes.string,
  author4Name: PropTypes.element,
  author2Name: PropTypes.element,
  author1Name: PropTypes.element,
  heading1: PropTypes.element,
  author3Alt: PropTypes.string,
  author4Alt: PropTypes.string,
  content1: PropTypes.element,
  review4: PropTypes.element,
  author3Name: PropTypes.element,
  author1Position: PropTypes.element,
  author2Alt: PropTypes.string,
  author2Src: PropTypes.string,
  author1Alt: PropTypes.string,
  review3: PropTypes.element,
  author4Src: PropTypes.string,
}

export default Testimonial17
