import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer4.css'

const Footer4 = (props) => {
  return (
    <footer className="footer4-footer7 thq-section-padding">
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-content">
          <div className="footer4-logo"></div>
          <div className="footer4-links">
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              {props.link1 ?? (
                <Fragment>
                  <span className="footer4-text07 thq-body-small">Home</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              {props.link2 ?? (
                <Fragment>
                  <span className="footer4-text06 thq-body-small">
                    All Psychics
                  </span>
                </Fragment>
              )}
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              {props.link3 ?? (
                <Fragment>
                  <span className="footer4-text11 thq-body-small">Press</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              {props.link4 ?? (
                <Fragment>
                  <span className="footer4-text05 thq-body-small">Contact</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              {props.link5 ?? (
                <Fragment>
                  <span className="footer4-text04 thq-body-small">Quiz</span>
                </Fragment>
              )}
            </a>
          </div>
        </div>
        <div className="footer4-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="thq-body-small">© 2024 Psychic House</span>
            </div>
            <div className="footer4-footer-links">
              <a
                href="https://bosphorusdigital.com.tr/privacy-policy/"
                target="_blank"
                rel="noreferrer noopener"
                className="footer4-text01"
              >
                {props.privacyLink ?? (
                  <Fragment>
                    <span className="footer4-text08 thq-body-small">
                      Privacy Policy
                    </span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://bosphorusdigital.com.tr/terms-of-use/"
                target="_blank"
                rel="noreferrer noopener"
                className="footer4-text02"
              >
                {props.termsLink ?? (
                  <Fragment>
                    <span className="footer4-text10 thq-body-small">
                      Terms and Conditions
                    </span>
                  </Fragment>
                )}
              </a>
              <span>
                {props.cookiesLink ?? (
                  <Fragment>
                    <span className="footer4-text09 thq-body-small">
                      Cookies Policy
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer4.defaultProps = {
  link5: undefined,
  link4: undefined,
  link2: undefined,
  link1: undefined,
  privacyLink: undefined,
  cookiesLink: undefined,
  termsLink: undefined,
  logoSrc:
    'https://images.unsplash.com/photo-1464146072230-91cabc968266?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzExNzI4Nnw&ixlib=rb-4.0.3&q=80&h=1500',
  link3: undefined,
}

Footer4.propTypes = {
  link5: PropTypes.element,
  link4: PropTypes.element,
  link2: PropTypes.element,
  link1: PropTypes.element,
  privacyLink: PropTypes.element,
  cookiesLink: PropTypes.element,
  termsLink: PropTypes.element,
  logoSrc: PropTypes.string,
  link3: PropTypes.element,
}

export default Footer4
