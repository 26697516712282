import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import CTA26 from '../components/cta26'
import Steps2 from '../components/steps2'
import Testimonial17 from '../components/testimonial17'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Stable Secondary Pheasant</title>
        <meta property="og:title" content="Stable Secondary Pheasant" />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="home-text thq-body-small thq-link">
              All Psychics
            </span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text01 thq-body-small thq-link">Press</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text02 thq-body-small thq-link">Contact</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text03 thq-body-small thq-link">Quiz</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text04 thq-body-large">Page One</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text05 thq-body-large">Page Two</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text06 thq-body-large">Page Three</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text07 thq-body-large">Page Four</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text08">Homepage</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text09">Download for iOS</span>
          </Fragment>
        }
        page1Description={
          <Fragment>
            <span className="home-text10 thq-body-small">
              Page One Description
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text11 thq-body-small">
              Page Two Description
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text12 thq-body-small">
              Page Three Description
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="home-text13 thq-body-small">
              Page Four Description
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Hero17
        action1={
          <Fragment>
            <span className="home-text14 thq-body-small">Download for iOS</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <p className="home-text15 thq-body-large">
              Connect with experienced psychics offering Tarot, Clairvoyance,
              Coffee Cup, and Dream Interpretation services. Discover insights
              and guidance for your life&apos;s journey.
            </p>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h1 className="home-text16 thq-heading-1">
              Find Your Perfect Psychic Match
            </h1>
          </Fragment>
        }
      ></Hero17>
      <CTA26
        action1={
          <Fragment>
            <span className="home-text17">Browse Psychics</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <p className="home-text18 thq-body-large">
              Explore our talented psychics and discover the one who resonates
              with you.
            </p>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text19 thq-heading-2">
              Find Your Perfect Psychic Match
            </span>
          </Fragment>
        }
      ></CTA26>
      <Steps2
        step1Title={
          <Fragment>
            <h2 className="home-text20 thq-heading-2">Find Your Psychic</h2>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <h2 className="home-text21 thq-heading-2">Select Your Reading</h2>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <h2 className="home-text22 thq-heading-2">
              Connect with Your Psychic
            </h2>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <h2 className="home-text23 thq-heading-2">
              Gain Clarity and Guidance
            </h2>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text24 thq-body-small">
              Browse through our list of experienced psychics and choose the one
              that resonates with you.
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text25 thq-body-small">
              Choose from a variety of readings such as Tarot, Clairvoyance,
              Coffee Cup, or Dream Interpretation.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text26 thq-body-small">
              Connect with your chosen psychic for a personalized and insightful
              reading session.
            </span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text27 thq-body-small">
              Receive guidance, clarity, and answers to your questions through
              the psychic reading.
            </span>
          </Fragment>
        }
      ></Steps2>
      <Testimonial17
        review1={
          <Fragment>
            <span className="home-text28 thq-body-small">
              I was amazed by the accuracy of the Tarot reading I received. The
              psychic was insightful and provided guidance that truly resonated
              with me.
            </span>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text29 thq-body-small">
              The Clairvoyance session I had was eye-opening. The psychic was
              able to tap into aspects of my life that I hadn&apos;t shared with
              anyone. Highly recommended!
            </span>
          </Fragment>
        }
        review3={
          <Fragment>
            <span className="home-text30 thq-body-small">
              Dream Interpretation helped me understand recurring dreams that
              had been bothering me for months. The psychic&apos;s
              interpretation brought clarity and peace of mind.
            </span>
          </Fragment>
        }
        review4={
          <Fragment>
            <span className="home-text31 thq-body-small">
              The Coffee Cup reading I received was surprisingly accurate. It
              provided me with valuable insights and a new perspective on my
              current situation.
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text32 thq-body-small">
              Read what our users have to say about their experiences with our
              psychics.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="home-text33 thq-heading-2">User Reviews</h2>
          </Fragment>
        }
        author1Name={
          <Fragment>
            <strong className="home-text34 thq-body-large">Sara Johnson</strong>
          </Fragment>
        }
        author2Name={
          <Fragment>
            <strong className="home-text35 thq-body-large">Michael Lee</strong>
          </Fragment>
        }
        author3Name={
          <Fragment>
            <strong className="home-text36 thq-body-large">
              Sophie Martinez
            </strong>
          </Fragment>
        }
        author4Name={
          <Fragment>
            <strong className="home-text37 thq-body-large">David Brown</strong>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text38 thq-body-small">Pisces, 23</span>
          </Fragment>
        }
        author2Position={
          <Fragment>
            <span className="home-text39 thq-body-small">Aries 31</span>
          </Fragment>
        }
        author3Position={
          <Fragment>
            <span className="home-text40 thq-body-small">Taurus, 30</span>
          </Fragment>
        }
        author4Position={
          <Fragment>
            <span className="home-text41 thq-body-small">Gemini, 45</span>
          </Fragment>
        }
      ></Testimonial17>
      <Footer4
        link1={
          <Fragment>
            <span className="home-text42 thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text43 thq-body-small">All Psychics</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text44 thq-body-small">Press</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text45 thq-body-small">Contact</span>
          </Fragment>
        }
        link5={
          <Fragment>
            <span className="home-text46 thq-body-small">Quiz</span>
          </Fragment>
        }
        termsLink={
          <Fragment>
            <span className="home-text47 thq-body-small">
              Terms and Conditions
            </span>
          </Fragment>
        }
        cookiesLink={
          <Fragment>
            <span className="home-text48 thq-body-small">Cookies Policy</span>
          </Fragment>
        }
        privacyLink={
          <Fragment>
            <span className="home-text49 thq-body-small">Privacy Policy</span>
          </Fragment>
        }
      ></Footer4>
    </div>
  )
}

export default Home
